:root {
  --main-bg-color: #030c5e;
  --second-bg-color: #050d50;
  --gradient-color-1: #ce09ff;
  --gradient-color-2: #ffa34e;
  --gradient-color-3: #ce09ff;
  --gradient-color-4: #48f573;
  --main-black: #010845;
  --second-black: #030b4d;
  --light-bg: #2f1385;
  --blur: #ff00f5;
}

* {
  font-family: 'Farro', sans-serif;
  color: #ffffff;
}

html, body {
  background: #260F6A;
}

#root {
  display: flex;
  align-items: center;
  justify-content: center;
}

html, body, #root {
  height: 100%;
}

.logo {
  width: 197px;
  height: 70px;
}

.logo-container {
  text-align: center;
}

.container {
  display: flex;
  flex-direction: column;

  background: #2F1385;

  border-radius: 8px;

  padding-top: 16px;
}

.content {
  padding: 0 32px;

  display: flex;
  justify-content: center;
  flex-direction: column;
}

.description {
  margin-bottom: 16px;
}

button.primary {
  outline: 0;
  border: 0;
  cursor: pointer;

  height: 56px;
  border-radius: 8px;
  text-decoration: none;

  background: linear-gradient(270.9deg, var(--gradient-color-1) -2.92%, var(--gradient-color-2) 99.95%);
}

button.primary:hover {
  background: linear-gradient(71.22deg, var(--gradient-color-3) 11.83%, var(--gradient-color-4) 95.13%);
}

.socials {
  margin-top: 32px;
  margin-bottom: 64px;

  display: flex;
  justify-content: center;
}

.socials > img:not(:first-child) {
  margin-left: 8px;
}

hr {
  border: 1px solid #33247a;

  margin: 32px 0;
}

.footer {
  padding: 32px;

  display: flex;
  justify-content: center;

  background: #200D5B;
}

.footer > img:not(:first-child) {
  margin-left: 8px;
}